import './CipherForm.css';

import { Form, Formik } from 'formik';
import React, { Component, useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';

class CipherForm extends Component {
  constructor(props) {
    super(props);
    this.wizard = React.createRef();
    this.formik = React.createRef();
    this.state = {
      progress_incriment: 100 / this.props.children.length,
      progress: 0,
    };
  }

  componentDidMount() {
    console.log("Context", this.context)
  }

  handleNextStep(e, target_errors, albus) {
    let errors = []
    for (const [key, value] of Object.entries(e)) {
      errors.push(key)
    }
    console.log("Errors", errors)
    if (target_errors.some(r => errors.includes(r))) {
      target_errors.forEach(r => {
        this.formik.current.setFieldTouched(r, true)
      })
    } else {
      this.setState({
        progress: this.state.progress + this.state.progress_incriment
      }, () => {
        albus.next()
        console.log("Progress", this.state.progress)
        this.props.progress(this.state.progress)
      })
      console.log("Formik", this.formik.current)
    }
  }

  handlePreviousStep(albus) {
    this.setState({
      progress: this.state.progress - this.state.progress_incriment
    }, () => {
      albus.previous()
      this.props.progress(this.state.progress)
    })
  }


  render() {
    const StepForm = ({ children, title, description }) =>
      <div className="FC_shell_container">
        <div className="FC_title">
          {title}
        </div>
        <div className="FC_text" style={{ paddingBottom: "10px" }}>
          {description}
        </div>
        {children}
        <hr />
      </div>
    const WizardNav = ({ previous_step_enabled, errors_array, albus }) =>
      <div className="FC_control_buttons_div">
        {
          previous_step_enabled && (
            <div className="FC_control_button_left">
              <button className="FC_button_cipher" onClick={() => {
                this.handlePreviousStep(albus)
              }}>
                {"Back"}
              </button>
            </div>
          )
        }
        <div className="FC_control_buttons_divider" />
        {
          <div className="FC_control_button_right">
            <button className="FC_button_cipher"
              onClick={() => this.formik.current.validateForm().then((e) => {
                this.handleNextStep(e, errors_array, albus)
              })}>Next</button>
          </div>
        }
      </div>
    const SubmitNav = ({ previous_step_enabled, albus }) =>
      <div className="FC_control_buttons_div">
        {
          previous_step_enabled && (
            <div className="FC_control_button_left">
              <button className="FC_button_cipher" onClick={() => {
                this.handlePreviousStep(albus)
              }}>
                {"Back"}
              </button>
            </div>
          )
        }
        <div className="FC_control_buttons_divider" />
        {
          <div className="FC_control_button_right">
            <button className="FC_button_cipher" type="submit" disabled={this.formik.current && this.formik.current.isSubmitting}
              onClick={() => {
                this.setState({
                  progress: this.state.progress + this.state.progress_incriment
                }, () => {
                  this.props.progress(this.state.progress)
                  this.formik.current.submitForm()
                })
              }}>Submit</button>
          </div>
        }
      </div>
    return (
      <>
        <Formik
          innerRef={this.formik}
          initialValues={this.props.initialValues}
          validationSchema={this.props.validationSchema}
          validate={values => this.props.validate(values)}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              //alert(JSON.stringify(values, null, 2));
              this.props.onReturnValues(values)
              setSubmitting(false);
            }, 400);
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              {/*<Persist name="creator-form" />*/}
              <Wizard ref={this.wizard}>
                <Steps>
                  {
                    this.props.children && Array.isArray(this.props.children) ?
                      this.props.children.map((item, index) => (
                        <Step
                          key={`step_${index}`}
                          id={`step_${index}`}
                          render={(props) => (
                            <>
                              {console.log("Albus Props", props)}
                              <StepForm
                                title={item.props && item.props.title ? item.props.title : ""}
                                description={item.props && item.props.description ? item.props.description : ""}
                              >
                                {item}
                              </StepForm>
                              {
                                index === this.props.children.length - 1 ?
                                  <SubmitNav previous_step_enabled={true} index={index} albus={props} />
                                  :
                                  <WizardNav previous_step_enabled={index === 0 ? false : true} errors_array={item.props.step_errors} albus={props} />
                              }
                            </>
                          )}
                        />
                      ))
                      :
                      [this.props.children].map((item, index) => (
                        <Step
                          key={`step_${index}`}
                          id={`step_${index}`}
                          render={(props) => (
                            <>
                              {console.log("Albus Props", props)}
                              <StepForm
                                title={item.props && item.props.title ? item.props.title : ""}
                                description={item.props && item.props.description ? item.props.description : ""}
                              >
                                {item}
                              </StepForm>

                              <SubmitNav previous_step_enabled={false} index={index} albus={props} />
                            </>
                          )}
                        />
                      ))
                  }
                </Steps>
              </Wizard>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
export default CipherForm;