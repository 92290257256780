import { useFormikContext, Formik, Form, Field } from 'formik';
import React, { Component, useEffect, useState } from 'react';
import FormError from './FormError';

function FormField(props) {
  const { values, errors, submitForm, setFieldTouched, setFieldValue } = useFormikContext();
  return (
    <div className="FC_form_field">
      <label htmlFor={props.name} className="FC_label">
        {
          props.use_custom_title ?
          props.custom_title
          :
          props.title
        }
      </label>
      <div className="FC_field_container">
        <label className="FC_field_label">
          <div className="FC_field_ltr">
            <div className="FC_field_wrapper">
              <Field name={props.name} type={props.type} className="FC_field" placeholder={props.placeholder ? props.placeholder : props.title} />
            </div>
          </div>
        </label>
      </div>
      {
        props.minor && (
          <div className="FC_field_minor_text">{props.minor}</div>
        )
      }
      <FormError errors={errors} title={props.title} name={props.name} />
    </div>
  )
}

export default FormField;