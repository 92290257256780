import MNA from './Assets/docs/SG-MNDA-2021.pdf'
import PSA from './Assets/docs/SG-PSA-2021.pdf'
import Electrolyte from './Assets/docs/Electrolyte-Software-Evaluation-License-Agreement.pdf'

export const PDFS = [
  {
    value: MNA,
    label: "Mutual Nondisclosure Agreement",
  },
  {
    value: PSA,
    label: "Professional Services Agreement",
  },
  {
    value: Electrolyte,
    label: "Electrolyte Software License Agreement",
  },
];

