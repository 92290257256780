import './SignalBanner.css';

import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { SizeMe } from 'react-sizeme';
import Select from 'react-select';
import { PDFS } from '../../Utils';

class SignalBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
    this.state = {

    };
  }

  componentDidMount() {

  }

  onSelect(event) {
    const link = document.createElement('a');
    link.href = event.value;
    link.download = event.label;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {

    return (
      <>
        <SizeMe monitorHeight>
          {({ size }) =>
            <>
              <div className="sb-wrapper">
                <svg className="sb-curve" fill="none" preserveAspectRatio="none" viewBox="0 0 1680 40">
                  <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path>
                </svg>
                <div className="sb-image-background">

                </div>
                <div className="sb-overlay">
                  <Container className="sb-container">
                    <Row className="sb-row">
                      <Col className="sb-col sb-col-l" xs={12} lg={7}>
                        <div>
                          <h1 className="sb-title">
                            Signal Garden Documents
                          </h1>
                          <p className="sb-description">
                            We look forward to working with you!
                          </p>
                        </div>
                      </Col>
                      <Col className="sb-col sb-col-r" xs={12} lg={5}>
                        <Select
                          options={PDFS}
                          className="sb-select"
                          placeholder="Select Document"
                          classNamePrefix="sb-select"
                          onChange={this.onSelect}
                          defaultValue=""
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </>
          }
        </SizeMe>
      </>
    );
  }
}
export default SignalBanner;