import { useFormikContext, Formik, Form, Field } from 'formik';
import React, { Component, useEffect, useState } from 'react';

function FormError(props) {
  const { values, touched, submitForm, setFieldTouched, setFieldValue } = useFormikContext();
  return (
    <>
      {
        props.errors[props.name] && touched[props.name]?
          <div className="FC_form_error_container">
            <div className="FC_form_error_marker_container">
              
            </div>
            <div className="FC_form_error_result_container">
              <div className="FC_form_error_result_text_wrapper">
                <div className="FC_form_error_result_text_container">
                  <div className="FC_form_error_result_title">
                    {props.title}
                  </div>
                  <div style={{ marginTop: "4px" }}>
                    <div className="FC_form_error_result_text">
                      {props.errors[props.name]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <span />
      }
    </>
  )
}

export default FormError;