import "./App.css";

import React from "react";
import PDF from "./Components/PDF/PDF";
import SignalNav from "./Components/SignalNav /SignalNav";
import SignalBanner from "./Components/SignalBanner/SignalBanner";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <SignalNav />
        <SignalBanner/>
      </>
    );
  }
}
export default App;
