import "./SignalNav.css";
import React, { useState } from 'react';
import { motion } from "framer-motion"
import logo from '../../Assets/logo/logo_no_text_black.png';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 910 })
  return isDesktop ? children : null
}

class SignalNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTopOfScreen: true,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.setState({ isTopOfScreen: window.scrollY < 60 })
  }

  render() {
    const navbarMotion = {
      solid: {
        backgroundColor: "rgba(255,255,255, 0)",
        height: "80px"
      },
      liquid: {
        backgroundColor: "rgba(255,255,255, 1)",
        height: "60px"
      },
    }
    const navbarLogo = {
      solid: {
        scale: 1
      },
      liquid: {
        scale: 0.75
      },
    }
    return (
      <>
        <motion.div
          animate={this.state.isTopOfScreen ? "solid" : "liquid"}
          transition={{ ease: "easeOut", duration: 0.3 }}
          variants={navbarMotion}
          className="SN-header"
        >
          <Navbar.Brand href="https://signalgarden.com/">
            <motion.div
              animate={this.state.isTopOfScreen ? "solid" : "liquid"}
              transition={{ ease: "easeOut", duration: 0.3 }}
              variants={navbarLogo}
            >
              <img
                className={`${this.state.isTopOfScreen ? "SN-logo-solid" : "SN-logo-liquid"} d-inline-block align-top`}
                src={logo}
              />
            </motion.div>
          </Navbar.Brand>
          <Nav className="SN-nav ml-auto">
            <Nav.Link href="https://signalgarden.com/" className={`${this.state.isTopOfScreen ? "SN-nav-item-solid" : "SN-nav-item-liquid"} SN-nav-item`}>Home</Nav.Link>
          </Nav>
        </motion.div>
      </>
    );
  }
}
export default SignalNav;