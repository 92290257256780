import './PDF.css';
import React from 'react';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { PDFS } from '../../Utils';
import Select from 'react-select';
import * as Yup from 'yup';
import CipherForm from '../CipherForm/CipherForm';
import FormField from '../CipherForm/FormFields/FormField';
import { Row, Col, Container } from 'react-bootstrap';

let pdf;

class PDF extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pdf_buffer: [],
      form_inputs: [],
      form_title: '',
    };
  }

  async fetchPDF(URL, title) {
    this.setState({ form_title: title });
    fetch(URL)
      .then((pdf_res) => {
        console.log("PDF", pdf_res)
        pdf_res.arrayBuffer()
          .then((buffer) => {
            console.log("PDF-BUFFER", buffer)
            this.setState({ pdf_buffer: buffer })
            this.setPDF();
          })
      })
  }

  async setPDF() {
    pdf = await PDFDocument.load(this.state.pdf_buffer);
    this.createFormInputs();
  }

  async createFormInputs() {
    const form = pdf.getForm()
    const fields = form.getFields()
    fields.forEach(field => {
      const type = field.constructor.name
      const name = field.getName()
      console.log(`${type}: ${name}`)
    })
    this.setState({ form_inputs: fields })
  }

  async savePDF() {
    await pdf.save()
      .then((finalized_pdf) => {
        console.log("DONE", finalized_pdf)
        const blob = new Blob([finalized_pdf], { type: 'application/pdf' });
        console.log("BLOB", blob)
        var fileURL = URL.createObjectURL(blob);
        //window.open(fileURL);
      })
  }

  componentDidMount() {

  }

  render() {

    return (
      <>
        <Container>
          <Row>
            <Col>
              <hr />
              <Select options={PDFS} onChange={res => { this.fetchPDF(res.value, res.label) }} />
              <hr />
              {
                this.state.form_inputs && this.state.form_inputs.length > 0 && (
                  <CipherForm
                    progress={size => {

                    }}
                    initialValues={{

                    }}
                    validationSchema={
                      Yup.object().shape({

                      })
                    }
                    validate={values => {
                      const errors = {};
                      return errors;
                    }}
                    onReturnValues={
                      values => {
                        console.log("SUBMIT", values)
                      }
                    }
                  >

                    <div
                      step_errors={[]}
                      title={this.state.form_title}
                    >
                      {this.state.form_inputs.map((item, index) => {
                        if (item.constructor.name.includes("PDFTextField")) {
                          return (
                            <FormField type="text" name={item.getName()} title={item.getName()} />
                          )
                        }
                      })}
                    </div>

                  </CipherForm>
                )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default PDF;